import React, { ButtonHTMLAttributes } from "react";
import { FaSpinner } from "react-icons/fa";
import { Container } from "./styles";

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  type?: "submit" | "reset" | "button";
  behavior: "primary" | "secondary";
  styleContainer?: object;
  loading?: boolean;
  disabled?: boolean;
  icon?: any;
}

const Button: React.FC<IButton> = ({
  text,
  type = "button",
  loading = false,
  disabled = false,
  behavior = "primary",
  styleContainer,
  icon: Icon,
  ...rest
}) => (
  <Container behavior={behavior} style={styleContainer} disabled={disabled}>
    <button type={type} {...rest} disabled={loading || disabled}>
      {loading ? (
        <FaSpinner size={24} style={{ margin: "auto" }} />
      ) : (
        <p>{text}</p>
      )}
      {Icon && <Icon />}
    </button>
  </Container>
);

export default Button;
