import styled from 'styled-components';
import bgWaves from 'shared/assets/images/bg-waves.png';
import colors from 'shared/constants/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: ${colors.gray.light02};
  width: 100%;
  height: 100vh;

  background: url(${bgWaves}) repeat-x center bottom;
  background-size: 100vw 50vh;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: row;

  width: 100%;

  justify-content: center;
  align-items: center;

  position: relative;

  background: ${colors.gray.white};
  box-shadow: 0 0 5px 6px rgba(0, 0, 0, 0.1);

  height: 7.2rem;

  img {
    height: 6rem;
  }
`;

export const ContentBox = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;

  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 38rem;

  background: ${colors.gray.white};
  padding: 3rem;
  margin-left: 5rem;

  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 3rem;

  h1 {
    font-family: 'Open Sans';
    font-weight: bold;
    font-size: 3.7rem;
    line-height: 120%;

    color: ${colors.gray.dark02};

    margin-bottom: 2rem;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;

    text-decoration: none;

    background: ${colors.gray.white};
    transition: border 0.3s;
    transition: color 0.3s;

    font-family: Open Sans;
    font-size: 1.5rem;
    line-height: 120%;
    color: ${colors.secondary.default};

    margin-top: 2rem;

    :hover {
      color: ${colors.secondary.default};
    }
  }
`;

export const Input = styled.input<{ isErrored: boolean }>`
  display: flex;
  height: 4.8rem;
  width: 100%;

  background: ${colors.gray.light02};

  border: none;
  border-radius: 5rem;

  box-shadow: inset 0px 4px 4px 'rgba(77, 76, 76, 0.2)';

  padding-left: 2rem;

  font-family: 'Open Sans';
  font-size: 1.6rem;
  line-height: 150%;
  color: ${colors.gray.dark02};

  border: ${(props) => (props.isErrored
    ? `1px solid ${colors.suport.alert}`
    : `1px solid ${colors.gray.light02}`)};

  &::placeholder {
    color: ${colors.gray.dark01};
  }

  @media (max-width: 650px) {
    height: 6rem;
  }
`;

export const ButtonSubmit = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  border: none;
  border-radius: 5rem;

  margin-top: 2rem;

  height: 5.6rem;

  background: ${colors.primary.default};

  font-family: 'Open Sans';
  font-size: 2.1rem;
  line-height: 120%;
  color: ${colors.gray.light02};
  transition: background 0.2s;

  :hover {
    background: ${colors.primary.default};
  }

  svg {
    animation: rotate 2s linear infinite;

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;
