import { useField } from "@unform/core";
import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Container, IconButton } from "./styles";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  containerStyle?: object;
  icon?: any;
  onclickIcon?: () => void;
  onBlurExecute?: (value: string) => void;
}

const InputRef: React.FC<InputProps> = ({
  name,
  containerStyle,
  icon: Icon,
  onclickIcon,
  onBlurExecute,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  const handleInputBlur = useCallback((): void => {
    setIsFocus(false);
    if (onBlurExecute) {
      onBlurExecute(inputRef.current?.value || "");
    }

    setIsFilled(!!inputRef.current?.value);
  }, [onBlurExecute]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <Container
      style={containerStyle}
      hasIcon={!!Icon}
      isErrored={!!error}
      isFocused={isFocused}
      isFilled={isFilled}
    >
      <input
        name={name}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        ref={inputRef}
        defaultValue={defaultValue}
        {...rest}
      />
      {Icon && (
        <IconButton type="button" onClick={() => onclickIcon && onclickIcon()}>
          <Icon />
        </IconButton>
      )}
    </Container>
  );
};

export default InputRef;
