import colors from "shared/constants/colors";
import IAppointment from "shared/interfaces/IAppointment";
import IProfessional from "shared/interfaces/IProfessional";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import Button from "../../components/Button";
import Text from "../../components/Text";
import {
  searchAppointments,
  searchProfessionals,
  updateAppointment,
} from "../../services/api-graphql-calls";
import orderArrayOfObjects from "../../utils/orderArrayOfObjects";
import ModalAddAppointmentToProfessional from "./ModalAddAppointmentToProfessional";
import ModalAddProfessional from "./ModalAddProfessional";
import * as Styles from "./styles";
import TableAppointments from "./TableAppointments";

interface IAppointmentsByProfessional {
  professional: IProfessional;
  appointments: IAppointment[];
}

const Appointments: React.FC = () => {
  const location = useLocation() as { state: { date: string | undefined } };
  const [appointmentsByProfessional, setAppointmentsByProfessional] = useState<
    IAppointmentsByProfessional[]
  >([]);
  const [appointments, setAppointments] = useState<IAppointment[]>([]);
  const [professionals, setProfessionals] = useState<IProfessional[]>([]);
  const [addProfessionalModal, setAddProfessionalModal] = useState(false);
  const [addAppointmentToProfessionalId, setAddAppointmentToProfessionalId] =
    useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const appointmentsNotScheduled = useMemo(
    () => appointments.filter((ap) => !ap.professional_id),
    [appointments]
  );

  useEffect(() => {
    const appointmentsFormatted = appointments.reduce<
      IAppointmentsByProfessional[]
    >((accumulator, appointment) => {
      if (!appointment?.professional_id) {
        return accumulator;
      }
      const professional_index = accumulator.findIndex(
        (t) => t.professional.id === appointment.professional_id
      );
      if (professional_index >= 0) {
        accumulator[professional_index] = {
          ...accumulator[professional_index],
          appointments: [
            ...accumulator[professional_index].appointments,
            appointment,
          ],
        };
        return accumulator;
      }
      accumulator.push({
        professional: appointment.professional,
        appointments: [appointment],
      });
      return accumulator;
    }, []);
    setAppointmentsByProfessional(appointmentsFormatted);
  }, [appointments]);

  const handleAddProfessional = useCallback((professional: IProfessional) => {
    setAppointmentsByProfessional((old) => [
      ...old,
      {
        professional,
        appointments: [],
      },
    ]);
  }, []);

  const handleAddToProfessional = useCallback(
    async (app: IAppointment) => {
      try {
        const new_app = await updateAppointment(app);
        setAppointments((oldAppointments) => {
          const a = oldAppointments.map((ap) =>
            ap.id === new_app.id
              ? {
                  ...ap,
                  ...new_app,
                  professional: professionals.find(
                    (p) => p.id === new_app.professional_id
                  ) as any,
                }
              : ap
          );
          return orderArrayOfObjects(a, "hour") as IAppointment[];
        });
      } catch (error: any) {
        toast(error?.response?.data?.message || "Erro no servidor", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "error",
        });
      }
    },
    [professionals]
  );

  const handleRemoveToProfessional = useCallback(
    async (appointment_id: string) => {
      const appointment = await updateAppointment({
        id: appointment_id,
        professional_id: null,
        hour: null,
      });
      setAppointments((oldAppointments) =>
        oldAppointments.map((ap) =>
          ap.id === appointment.id ? { ...ap, ...appointment } : ap
        )
      );
    },
    []
  );

  const loadAppointments = useCallback(async () => {
    try {
      setLoading(true);
      const results = await searchAppointments({
        where: {
          from: location.state.date,
          to: location.state.date,
          statusNotEqual: -1,
        },
        orderBy: [{ date: "desc" }, { hour: "asc" }],
      });

      setAppointments(results);
    } catch (error) {
      toast("Erro ao carregar agendamentos do dia", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [location.state.date]);

  useEffect(() => {
    loadAppointments();
  }, [loadAppointments]);

  const loadData = useCallback(async () => {
    try {
      const resultsProfessionals = await searchProfessionals();

      const filteredProfessionals = resultsProfessionals;

      setProfessionals(filteredProfessionals);
    } catch (error: any) {
      toast(error?.response?.data?.message || "Erro no servidor", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "error",
      });
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Styles.Container>
      <Text
        test="Controle de Agenda"
        size={28}
        color={colors.gray.dark01}
        weight="600"
        align="left"
      />
      <Text
        test="Data do atendimento"
        size={18}
        weight="700"
        color={colors.primary.default}
        align="left"
        marginTop={16}
        marginBottom={8}
      />
      <div>
        <Styles.Row>
          <Text
            test={String(location.state.date)}
            size={24}
            align="center"
            weight="600"
          />
          <div>
            <Button
              behavior="secondary"
              text="Adicionar professional"
              style={{ width: "100%" }}
              styleContainer={{ marginLeft: "2rem" }}
              onClick={() => setAddProfessionalModal(true)}
            />
          </div>
        </Styles.Row>
        <Styles.Divider />
        {loading && (
          <Styles.LoadingDiv>
            <FaSpinner size={40} />
          </Styles.LoadingDiv>
        )}
        {appointmentsByProfessional.map((group, index) => (
          <div key={index}>
            <Text
              test={group.professional.user?.name}
              size={18}
              weight="700"
              color={colors.primary.default}
              align="left"
              marginTop={16}
              marginBottom={8}
            />
            <TableAppointments
              appointments={group.appointments}
              handleRemoveToProfessional={handleRemoveToProfessional}
            />
            <Styles.ContainerAdd
              onClick={() =>
                setAddAppointmentToProfessionalId(group.professional.id)
              }
            >
              <FiPlus name="add" size={24} color={colors.primary.default} />
            </Styles.ContainerAdd>
          </div>
        ))}
      </div>
      <ModalAddProfessional
        visible={addProfessionalModal}
        handleCloseModal={() => setAddProfessionalModal(false)}
        handleAddProfessional={(data) => handleAddProfessional(data)}
        professionals={professionals}
      />
      <ModalAddAppointmentToProfessional
        visible={!!addAppointmentToProfessionalId}
        handleCloseModal={() => setAddAppointmentToProfessionalId(undefined)}
        handleAddToProfessional={(appointment) =>
          handleAddToProfessional(appointment)
        }
        professional_id={addAppointmentToProfessionalId}
        appointments={appointmentsNotScheduled}
      />
    </Styles.Container>
  );
};

export default Appointments;
