import Button from "components/Button";
import Input from "components/Input";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Content } from "./styles";
const Code = () => {
  const history = useHistory();
  const [code, setCode] = useState("");

  const handleCode = () => {
    if (code === "HOUSI") {
      history.push("/calendar?address_id=d19caf91-3bd2-4c8a-b55b-57bb0c9e57db");
    } else {
      toast.error("Codigo nao existe");
      return;
    }
  };

  return (
    <Container>
      <Content>
        <Input
          value={code}
          onChange={(e) => setCode(e.target.value.toUpperCase())}
          placeholder="entre com o codigo do evento"
        />
        <Button
          behavior="primary"
          text="ENTRAR NO EVENTO"
          styleContainer={{ width: "100%", marginTop: 16 }}
          style={{ width: "100%" }}
          onClick={() => handleCode()}
        />
      </Content>
    </Container>
  );
};

export default Code;
