import styled from 'styled-components';
import colors from 'shared/constants/colors';

export const ModalStyled = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 5rem 0;

  overflow: hidden;
  z-index: 10;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.gray.white};
  border-radius: 8px;
  margin: auto auto;
  padding: 4rem;
  border: 1px solid #888;
  width: 90%;
  max-width: 800px;
  overflow-y: auto;


  &::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: ${colors.gray.light02};        /* color of the tracking area */
    margin: 1px;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.secondary.default};    /* color of the scroll thumb */
    border-radius: 6px;       /* roundness of the scroll thumb */
  }
`;

export const Steps = styled.button`
  display: flex;
  flex-direction: row;
  place-content: center;
  padding-bottom: 1rem;

  width: 100%;
  height: 20px;
`;
