import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import React, { useRef, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { FiEyeOff } from "react-icons/fi";
import { toast } from "react-toastify";
import * as Yup from "yup";
import logo from "shared/assets/icons/logo.svg";
import InputRef from "../../components/InputRef";
import { useAuth } from "../../hooks/Auth";
import { ButtonSignIn, Container, Content, ContentBox, Header } from "./styles";
import bgWaves from "shared/assets/images/bg-waves.png";

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { signIn } = useAuth();

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (data: SignInFormData) => {
    setLoading(true);
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string().email().required(),
        password: Yup.string().required(),
      });

      await schema.validate(data, { abortEarly: false });

      await signIn({ email: data.email, password: data.password });
    } catch (error) {
      setLoading(false);
      toast("Email/senha incorretos", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "error",
      });
    }
  };

  return (
    <Container>
      <img src={bgWaves} alt="bg" />
      <Header>
        <img src={logo} alt="logo" />
      </Header>
      <ContentBox>
        <Content>
          <Form onSubmit={handleSubmit} ref={formRef}>
            <h1>Faça o login para acessar a sua conta zazuu admin</h1>
            <InputRef placeholder="email" name="email" type="email" />
            <InputRef
              placeholder="senha"
              name="password"
              type={passwordVisibility ? "text" : "password"}
              icon={FiEyeOff}
              onclickIcon={() => setPasswordVisibility((old) => !old)}
            />
            <ButtonSignIn type="submit">
              {loading ? <FaSpinner /> : "entrar"}
            </ButtonSignIn>
          </Form>
        </Content>
      </ContentBox>
    </Container>
  );
};

export default SignIn;
