import { gql } from "@apollo/client";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import api from "../services/api";
import client from "../services/api-graphql";

interface ICustomer {
  name: string;
  email: string;
  cep: string;
  ref_code: string;
  token: string;
}

interface IUser {
  name: string;
  email: string;
  roles?: {
    id: string;
    name: string;
  }[];
}

interface IAuthState {
  token: string;
  user: IUser;
  customer?: ICustomer;
}

interface ISignInCredentials {
  email: string;
  password: string;
}

interface IAuthContext {
  user: IUser;
  customer?: ICustomer;
  signIn(credentials: ISignInCredentials): Promise<void>;
  signOut(): void;
  updateCustomerDetails(customer: {
    name?: string;
    email?: string;
    cep?: string;
  }): void;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<IAuthState>(() => {
    const token = localStorage.getItem("@zazuu:admin:user:token");
    const user = localStorage.getItem("@zazuu:admin:user");
    const customer = localStorage.getItem("@zazuu:admin:customer");

    if (token && user) {
      if (customer) {
        const customerParsed = JSON.parse(customer);

        api.defaults.headers.authorization = `Bearer ${customerParsed.token}`;

        client.defaultOptions = {
          query: {
            context: {
              headers: {
                authorization: `Bearer ${customerParsed.token}`,
              },
            },
          },
          mutate: {
            context: {
              headers: {
                authorization: `Bearer ${customerParsed.token}`,
              },
            },
          },
        };
        return {
          token: customerParsed.token,
          user: JSON.parse(user),
          customer: customerParsed,
        };
      }

      api.defaults.headers.authorization = `Bearer ${token}`;
      client.defaultOptions = {
        query: {
          context: {
            headers: {
              authorization: `Bearer ${token}`,
            },
          },
        },
        mutate: {
          context: {
            headers: {
              authorization: `Bearer ${token}`,
            },
          },
        },
      };
      return { token, user: JSON.parse(user) };
    }

    return {} as IAuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const results = await client.query({
      query: gql`
        query ($role: String!, $password: String!, $email: String!) {
          signIn(role: $role, password: $password, email: $email) {
            token
            refreshToken
            user {
              id
              name
              email
              cpf
              phone
              avatar_url
              birthday
              address_main_id
              customer {
                ref_code
                exponent_push_token
              }
              roles {
                id
                name
              }
            }
          }
        }
      `,
      variables: {
        email,
        password,
        role: "promotor",
      },
    });

    const { token, user } = results.data.signIn;

    localStorage.setItem("@zazuu:admin:user:token", token);
    localStorage.setItem(
      "@zazuu:admin:user",
      JSON.stringify({
        name: user.name,
        email: user.email,
        roles: user.roles,
      })
    );

    api.defaults.headers.authorization = `Bearer ${token}`;
    client.defaultOptions = {
      query: {
        context: {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
      },
      mutate: {
        context: {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
      },
    };

    setData({
      token,
      user: {
        name: user.name,
        email: user.email,
        roles: user.roles,
      },
      customer: undefined,
    });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem("@zazuu:admin:user");
    localStorage.removeItem("@zazuu:admin:user:token");
    localStorage.removeItem("@zazuu:admin:customer");

    setData({} as IAuthState);
  }, []);

  const updateCustomerDetails = useCallback(
    (customer: { name?: string; email?: string; cep?: string }) => {
      setData({
        token: data.token,
        user: data.user,
        customer: data.customer ? { ...data.customer, ...customer } : undefined,
      });
    },
    [setData, data.user, data.token, data.customer]
  );

  useEffect(() => {
    localStorage.setItem("@zazuu:admin:user", JSON.stringify(data.user));
    if (data.customer) {
      localStorage.setItem(
        "@zazuu:admin:customer",
        JSON.stringify(data.customer)
      );
    }
  }, [data]);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        customer: data.customer,
        signIn,
        signOut,
        updateCustomerDetails,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContext {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuth };
