import colors from "shared/constants/colors";
import IAppointment from "shared/interfaces/IAppointment";
import React from "react";
import { FiTrash } from "react-icons/fi";
import Text from "../../../components/Text";
import * as Styles from "./styles";
import AppointmentStatusEnum from "../../../utils/enums/AppointmentStatusEnum";

interface ITableAppointments {
  appointments: IAppointment[];
  handleRemoveToProfessional: (appointment_id: string) => void;
}

const TableAppointments: React.FC<ITableAppointments> = ({
  appointments,
  handleRemoveToProfessional,
}) => {
  return (
    <Styles.Container>
      <Styles.Table>
        <thead>
          <tr>
            <th>
              <Text
                test="Horário"
                size={18}
                weight="600"
                color={colors.gray.white}
              />
            </th>
            <th>
              <Text
                test="Tutor"
                size={18}
                weight="600"
                color={colors.gray.white}
              />
            </th>
            <th>
              <Text
                test="Pet"
                size={18}
                weight="600"
                color={colors.gray.white}
              />
            </th>
            <th>
              <Text
                test="Raça"
                size={18}
                weight="600"
                color={colors.gray.white}
              />
            </th>
            <th>
              <Text
                test="Endereço"
                size={18}
                weight="600"
                color={colors.gray.white}
              />
            </th>
            <th>
              <Text
                test="Serviço"
                size={18}
                weight="600"
                color={colors.gray.white}
              />
            </th>
            <th>
              <Text
                test="ações"
                size={18}
                weight="600"
                color={colors.gray.white}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {appointments.map((appointment) => (
            <tr key={appointment.id}>
              <td
                style={{
                  backgroundColor:
                    appointment.status ===
                    AppointmentStatusEnum["Aguardando pagamento"]
                      ? colors.suport.warning
                      : colors.gray.white,
                }}
              >
                <Text
                  test={appointment?.hour || "sem hora"}
                  size={18}
                  color={
                    appointment.status ===
                    AppointmentStatusEnum["Aguardando pagamento"]
                      ? colors.gray.white
                      : colors.gray.dark03
                  }
                />
              </td>
              <td>
                <Text
                  test={appointment.customer.user.name}
                  size={18}
                  color={colors.gray.dark03}
                />
              </td>
              <td>
                <Text
                  test={appointment.pet.name}
                  size={18}
                  color={colors.gray.dark03}
                />
              </td>
              <td>
                <Text
                  test={appointment.pet?.breed?.name || "SRD"}
                  size={18}
                  color={colors.gray.dark03}
                />
              </td>
              <td>
                <Text
                  test={`CEP: ${appointment.address.cep}`}
                  size={18}
                  color={colors.gray.dark03}
                />
                <Text
                  test={`${appointment?.address?.street || ""}, ${
                    appointment?.address?.number || "SN"
                  } ${
                    appointment?.address?.complement
                      ? `- ${appointment?.address?.complement}`
                      : ""
                  } - ${appointment?.address?.neighborhood || ""}`}
                  size={18}
                  color={colors.gray.dark03}
                />
              </td>
              <td>
                <Text
                  test={String(appointment.service.name)}
                  size={18}
                  color={colors.gray.dark03}
                />
              </td>
              <td>
                <div>
                  <button
                    type="button"
                    onClick={() => handleRemoveToProfessional(appointment.id)}
                  >
                    <FiTrash color={colors.gray.dark02} />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Styles.Table>
    </Styles.Container>
  );
};

export default TableAppointments;
