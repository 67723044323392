import styled, { css } from 'styled-components';
import colors from 'shared/constants/colors';

export const Container = styled.div<{ behavior: 'primary' | 'secondary'; disabled: boolean }>`
  display: flex;
  flex-direction: row;
  height: 4.8rem;

  opacity: ${(props) => (props.disabled ? 0.3 : 1)};

  @media (max-width: 500px) {
    height: 6rem;
  }

  & > button {
    display: flex;
    height: 100%;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    padding: 0 4rem;
    border-radius: 3rem;
    font-family: 'Open Sans';
    font-size: 1.6rem;
    line-height: 150%;

    transition: all 0.3s ease;

    svg {
    animation: rotate 2s linear infinite;

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
  }

    & > p {
      white-space: nowrap;
      text-align: center;
      width: 100%;
      font-weight: 700;
    }

    ${(props) => props.behavior === 'primary'
    && css`
        background: ${colors.primary.default};
        color: ${colors.gray.white};
        border: 2px solid ${colors.primary.default};

        :hover:not(:disabled) {
          background: ${colors.primary.dark};
          color: ${colors.gray.white};
        }
      `}

    ${(props) => props.behavior === 'secondary'
    && css`
        background: transparent;
        color: ${colors.primary.default};
        border: 2px solid ${colors.primary.default};

        :hover:not(:disabled) {
          background: ${colors.gray.white};
        }
      `}

    @media (max-width: 500px) {
      height: 6rem;
    }
  }
`;
