import ReactSelect from 'react-select';
import styled from 'styled-components';
import colors from 'shared/constants/colors';

export const ReactSelectStyled = styled(ReactSelect)`
  color: ${colors.gray.dark01};
  box-shadow: none;
  border-color: transparent;

  .react-select__option {
    background-color: ${colors.gray.white};

    :hover {
      background-color: ${colors.gray.light02};
    }
  }

  .react-select__option--is-selected {
    color: ${colors.secondary.default};
  }

  .react-select__control {
    height: 4.8rem;
    border-radius: 2.4rem;
    background-color: ${colors.gray.light02};
    font-size: 1.6rem;

    @media (max-width: 500px) {
      height: 6rem;
    }
  }

  .react-select__control--is-focused {
    box-shadow: 0 0 0 1px ${colors.secondary.default};
    border: transparent;

    :hover {
      border-color: transparent;
    }
  }

  .react-select__control--menu-is-open {
    box-shadow: 0 0 0 1px ${colors.secondary.default};
    border: transparent;

    :hover {
      border-color: transparent;
    }
  }
`;
