import { gql } from "@apollo/client";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { addDays, format, parseISO } from "date-fns";
import { subHours } from "date-fns/esm";
import React, { useEffect, useRef, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import colors from "shared/constants/colors";
import IAppointment from "shared/interfaces/IAppointment";
import Button from "../../components/Button";
import InputRef from "../../components/InputRef";
import Text from "../../components/Text";
import client from "../../services/api-graphql";
import * as Styles from "./styles";

interface IAppointemntListRow {
  date: string;
  total_appointments: number;
  scheduled_appointments: number;
  appointments: IAppointment[];
}

const Calendar: React.FC = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const address_id = queryParameters.get("address_id");

  const formRef = useRef<FormHandles>(null);
  const [calendars, setCalendars] = useState<IAppointemntListRow[]>([]);
  const [loading, setLoading] = useState(false);

  const loadAppoointments = async ({
    from,
    to,
  }: {
    from: string;
    to: string;
  }) => {
    setLoading(true);

    try {
      const response = await client.query({
        query: gql`
          query ($from: String!, $to: String!, $addressId: String) {
            appointmentsByDay(from: $from, to: $to, address_id: $addressId) {
              date
              scheduled_appointments
              total_appointments
            }
          }
        `,
        variables: {
          from: from,
          to: to,
          addressId: address_id,
        },
      });

      setCalendars(response.data.appointmentsByDay);
    } catch (error) {
      toast("Erro ao carregar resumo", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async ({ from, to }: { from: string; to: string }) => {
    loadAppoointments({ from, to });
  };

  useEffect(() => {
    const today = subHours(new Date(), 3).toISOString();
    const addSevenDays = addDays(subHours(new Date(), 3), 7).toISOString();
    loadAppoointments({
      from: today.split("T")[0],
      to: addSevenDays.split("T")[0],
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Styles.Container>
      <Text
        test="Controle de Agenda"
        size={28}
        color={colors.gray.dark01}
        weight="600"
        align="left"
      />
      <Text
        test="Período"
        size={18}
        weight="700"
        color={colors.primary.default}
        align="left"
        marginTop={16}
      />
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Styles.Row>
          <InputRef
            name="from"
            style={{ paddingRight: "2rem" }}
            placeholder="data inicial"
            type="date"
            containerStyle={{ maxWidth: 300 }}
          />
          <InputRef
            name="to"
            containerStyle={{ marginLeft: "2rem", maxWidth: 300 }}
            style={{ paddingRight: "2rem" }}
            placeholder="data final"
            type="date"
          />
          <div style={{ marginLeft: "2rem" }}>
            <Button
              behavior="primary"
              text="Buscar"
              style={{ width: "100%" }}
              type="submit"
            />
          </div>
        </Styles.Row>
        {loading && (
          <Styles.LoadingDiv>
            <FaSpinner size={40} />
          </Styles.LoadingDiv>
        )}
        <Styles.Table>
          <thead>
            <tr>
              <th>
                <Text
                  test="data"
                  size={18}
                  weight="600"
                  color={colors.gray.white}
                />
              </th>
              <th>
                <Text
                  test="total de atendimentos"
                  size={18}
                  weight="600"
                  color={colors.gray.white}
                />
              </th>
              <th>
                <Text
                  test="ações"
                  size={18}
                  weight="600"
                  color={colors.gray.white}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {calendars.map((calendar) => (
              <tr key={calendar.date}>
                <td>
                  <Text
                    test={format(parseISO(calendar.date), "dd/MM/yyyy")}
                    size={18}
                    color={colors.gray.dark03}
                  />
                </td>
                <td>
                  <Text
                    test={`${calendar.scheduled_appointments}/${calendar.total_appointments} agendados`}
                    size={18}
                    color={colors.gray.dark03}
                  />
                </td>
                <td>
                  <div>
                    <Link
                      to={{
                        pathname: "/calendar-details",
                        state: {
                          date: calendar.date,
                        },
                      }}
                    >
                      <FiEdit color={colors.gray.dark02} />
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Styles.Table>
      </Form>
    </Styles.Container>
  );
};

export default Calendar;
