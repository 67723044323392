import React from "react";
import Modal from "../../../components/Modal";
import Select from "../../../components/Select";
import Text from "../../../components/Text";
import Button from "../../../components/Button";

import * as Styles from "./styles";
import IProfessional from "shared/interfaces/IProfessional";
import { Form } from "@unform/web";
import { useCallback } from "react";

interface IProps {
  visible: boolean;
  handleCloseModal: () => void;
  professionals: IProfessional[];
  handleAddProfessional: (professional: IProfessional) => void;
}

const ModalAddProfessional: React.FC<IProps> = ({
  visible,
  handleCloseModal,
  professionals,
  handleAddProfessional,
}) => {
  const handleSubmit = useCallback(
    (data: any) => {
      const professional = professionals.find(
        (p) => p.id === data.professional_id
      );

      if (!professional) {
        return;
      }

      handleAddProfessional(professional);
      handleCloseModal();
    },
    [professionals, handleAddProfessional, handleCloseModal]
  );

  return (
    <Modal visible={visible} closeModal={handleCloseModal}>
      <Styles.Content>
        <Text test="Add professional" size={24} align="center" weight="600" />
        <Form onSubmit={handleSubmit}>
          <Select
            name="professional_id"
            options={professionals.map((p) => ({
              value: p.id,
              label: p.user.name,
            }))}
            style={{
              marginTop: 16,
              minHeight: 200,
            }}
          />
          <Button
            behavior="primary"
            text="Adicionar"
            type="submit"
            style={{ width: "100%", marginTop: "1.6rem" }}
          />
        </Form>
      </Styles.Content>
    </Modal>
  );
};

export default ModalAddProfessional;
