import React, { useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "react-toastify";
import logo from "shared/assets/icons/logo.svg";
import api from "../../services/api";
import {
  ButtonSubmit,
  Container,
  Content,
  ContentBox,
  Header,
  Input,
} from "./styles";

const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [erro, setErro] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);

    const schema = Yup.object().shape({
      email: Yup.string().email().required(),
    });

    await schema
      .validate({ email }, { abortEarly: false })
      .then(async () => {
        await api.post("/password/forgot", { email, type: "customer" });
        toast("Senha resetada com sucesso. Verifique sua caixa de spam", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
        });

        setLoading(false);
      })
      .catch((error) => {
        toast("Erro ao recuperar senha", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "error",
        });

        setLoading(false);
        setErro(true);
      });
  };

  return (
    <Container>
      <Header>
        <img src={logo} alt="logo" />
      </Header>
      <ContentBox>
        <Content>
          <h1>Recupere sua senha</h1>
          <Input
            placeholder="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            isErrored={erro}
            onFocus={() => setErro(false)}
          />

          <ButtonSubmit type="button" onClick={() => handleSubmit()}>
            {loading ? <FaSpinner /> : "recuperar"}
          </ButtonSubmit>
          <div>
            <Link to="/">Voltar</Link>
          </div>
        </Content>
      </ContentBox>
    </Container>
  );
};

export default ForgotPassword;
