import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 5%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  box-shadow: -3px -1px 17px 10px rgba(185,179,179,0.75);
  -webkit-box-shadow: -3px -1px 17px 10px rgba(185,179,179,0.75);
  -moz-box-shadow: -3px -1px 17px 10px rgba(185,179,179,0.75);
  background-color: white;
  padding: 5%;
  border-radius: 16px;
`;
