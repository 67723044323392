import colors from 'shared/constants/colors';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 2rem;
`;


export const LoadingDiv = styled.div`
  @keyframes spin {
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
  }

  position: absolute;
  top: 50%;
  right: 50%;
  color: ${colors.primary.default};
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;

  @media(max-width: 800px) {
    flex-wrap: wrap;

    & > div:first-child {
      margin-top: 0;
    }

    & > div {
      margin-top: 2rem;
      margin-left: 0 !important;
      width: 100%;
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.secondary.default};
  margin: 2rem 0;
`;

export const ContainerAdd = styled.button`
  display: flex;
  width: 100%;
  padding: 8px;
  background-color: ${colors.gray.white};
  justify-content: center;
  border: 2px solid ${colors.secondary.default};
`;
