import styled from 'styled-components';
import colors from 'shared/constants/colors';

export const Modal = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  overflow-y: auto;
  z-index: 10;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.gray.white};
  border-radius: 3rem;
  margin: auto auto;
  padding: 2rem 4rem;
  border: 1px solid #888;
  width: 90%;
  max-width: 600px;
  overflow: auto;
`;

export const CepContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
`;

export const TitleFields = styled.strong`
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 150%;

  color: ${colors.primary.default};

  @media (max-width: 650px) {
    font-size: 2rem;
  }
`;

export const ButtonSubmit = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  border-radius: 5rem;

  margin: 3rem auto 2rem auto;
  padding: 0 4rem;

  height: 5.6rem;

  background: ${colors.primary.default};

  font-family: 'Open Sans';
  font-size: 2.1rem;
  line-height: 120%;
  color: ${colors.gray.light02};
  transition: background 0.2s;

  :hover {
    background: ${colors.secondary.default};
  }

  svg {
    animation: rotate 2s linear infinite;

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
  }

  @media (max-width: 650px) {
    margin-top: 3rem;
    height: 7.6rem;
  }
`;
