import Code from "pages/Code";
import React from "react";
import Calendar from "../pages/Calendar";
import CalendarDetails from "../pages/CalendarDetails";
import ForgotPassword from "../pages/ForgotPassword";
import SignIn from "../pages/SignIn";
import UpdateProfile from "../pages/UpdateProfile";
import Route from "./Route";

const Routes: React.FC = () => (
  <>
    <Route path="/" exact component={SignIn} />
    <Route path="/forgot-password" exact component={ForgotPassword} />

    <Route path="/update-profile" exact component={UpdateProfile} isPrivate />

    <Route path="/code" exact component={Code} isPrivate />
    <Route path="/calendar" exact component={Calendar} isPrivate />
    <Route
      path="/calendar-details"
      exact
      component={CalendarDetails}
      isPrivate
    />
  </>
);

export default Routes;
