import {
  gql,
} from '@apollo/client';
import IAppointment from 'shared/interfaces/IAppointment';
import IProfessional from 'shared/interfaces/IProfessional';
import client from './api-graphql';

interface IFiltersAppointments {
  pagination?: {
    limit: number;
    page: number;
  };
  orderBy?: {
    [key: string]: 'asc' | 'desc';
  }[];
  where: {
    from?: string;
    to?: string;
    statusNotEqual?: number;
  };
}

const searchAppointments = async (filters?: IFiltersAppointments): Promise<IAppointment[]> => {
  const response = await client.query({
    query: gql`
      query($filters: FiltersAppointmentInputType) {
        appointments(filters: $filters) {
          appointments {
            id
            date
            period
            hour
            professional_id
            observations
            status
            professional {
              id
              user {
                name
              }
            }
            pet {
              name
              specie {
                name
              }
              breed {
                name
              }
            }
            customer {
              user {
                name
              }
            }
            service {
              name
            }
            address {
              cep
              city
              state
              street
              neighborhood
              number
              complement
            }
            additionals {
              name
            }
          }
        }
      }
    `,
    variables: {
      filters,
    },
  });
  return response.data.appointments.appointments;
};

const searchProfessionals = async (): Promise<IProfessional[]> => {
  const response = await client.query({
    query: gql`
    query($filters: FiltersProfessionalInputType) {
      professionals(filters: $filters) {
        id
        status
        user {
          name
        }
      }
    }
    `,
    variables: {
      filters: {
        where: {
          status: true
        }
      }
    }
  });
  return response.data.professionals;
};

const updateAppointment = async (appointment: Partial<IAppointment>): Promise<IAppointment> => {
  const response = await client.mutate({
    mutation: gql`
      mutation($appointment: AppointmentUpdateInputType!) {
        updateAppointment(appointment: $appointment) {
          id
          hour
          professional_id
        }
    }
    `,
    variables: {
      appointment: {
        id: appointment.id,
        professional_id: appointment.professional_id,
        hour: appointment.hour,
      },
    },
  });
  return response.data.updateAppointment;
};

export { searchAppointments, searchProfessionals, updateAppointment };
