import { createGlobalStyle } from 'styled-components';
import colors from 'shared/constants/colors';

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  @media(max-width: 1080px){
    html {
      font-size: 50%;
    }
  }

  @media(max-width: 900px){
    html {
      font-size: 45%;
    }
  }

  @media(max-width: 550px){
    html {
      font-size: 40%;
    }
  }

  body {
    background: ${colors.primary.background};
    color: red;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    -webkit-tap-highlight-color: transparent;
  }

  button {
    cursor: pointer;
    background: none;
    border: none;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
`;
