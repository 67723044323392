import React, {
  InputHTMLAttributes,
  useCallback,
  useRef,
  useState,
} from "react";
import { Container, IconButton } from "./styles";

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  containerStyle?: object;
  icon?: any;
  onclickIcon?: () => void;
  onBlurExecute?: (value: string) => void;
  error?: boolean;
}

const Input: React.FC<IInputProps> = ({
  name,
  containerStyle,
  icon: Icon,
  onclickIcon,
  onBlurExecute,
  error = false,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  const handleInputBlur = useCallback((): void => {
    setIsFocus(false);
    if (onBlurExecute) {
      onBlurExecute(inputRef.current?.value || "");
    }

    setIsFilled(!!inputRef.current?.value);
  }, [onBlurExecute]);

  return (
    <Container
      style={containerStyle}
      hasIcon={!!Icon}
      isFocused={isFocused}
      isFilled={isFilled}
      isErrored={error}
    >
      <input
        name={name}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        ref={inputRef}
        {...rest}
      />
      {Icon && (
        <IconButton type="button" onClick={() => onclickIcon && onclickIcon()}>
          <Icon />
        </IconButton>
      )}
    </Container>
  );
};

export default Input;
